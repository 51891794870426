// platform urls
export const WEBSITE_URL = process.env.WEBSITE_URL ?? (process.env.NEXT_PUBLIC_WEBSITE_URL ?? (process.env.NEXT_PUBLIC_VERCEL_URL ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` : null)); // this website url
export const MUZOLOGY_API = process.env.MUZOLOGY_API ?? (process.env.NEXT_PUBLIC_MUZOLOGY_API ?? null); // muzology server api
export const MUZOLOGY_APP = process.env.MUZOLOGY_APP ?? (process.env.NEXT_PUBLIC_MUZOLOGY_APP ?? 'https://v3.muzology.com'); // muzology app frontend
export const ADMIN_API = 'https://dynamo.muzology.com'; // muzology admin api
export const MUZOLOGY_API_TOKEN = process.env.NEXT_PUBLIC_MUZOLOGY_TOKEN;
// export const MUZOLOGY_API = "https://muzology.ngrok.io";

// environment
export const BUILD_ENVIRONMENT = process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'development'; // development, preview, production
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const IS_PREVIEW = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

export const CODE_VERSION = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'development';
export const CODE_BRANCH = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ?? 'development';

// analytics / integrations
export const LOGROCKET_ID = process.env.NEXT_PUBLIC_LOGROCKET_ID ?? null;
export const ROLLBAR_TOKEN = process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN ?? null;
export const GOOGLE_ANALYTICS_UA = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_UA ?? null;
export const GOOGLE_ANALYTICS_GA4 = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GA4 ?? null;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? null;
export const HEAP_ANALYTICS = process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID ?? null;

// sandbox / production mode
export const SANDBOX_MODE = process.env.NEXT_PUBLIC_SANDBOX_MODE !== 'false';

// stripe
export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY ?? null;

// paypal
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ?? null;

// dato
export const DATO_API_TOKEN = process.env.NEXT_PUBLIC_DATO_API_TOKEN ?? '4f05ea2e9a2556be5649badd84f70c';
export const DATO_ENVIRONMENT = process.env.NEXT_PUBLIC_DATO_ENVIRONMENT ?? 'website-upgrades';

// auth

// editor
export const ENABLE_PAGE_EDITOR = false;
export const DISABLE_GIFS = false;

if (!MUZOLOGY_API) {
    console.error('MUZOLOGY_API not defined:', MUZOLOGY_API);
}

if (!WEBSITE_URL) {
    console.error('MUZOLOGY_API not defined:', WEBSITE_URL);
}

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
    console.info(`MUZOLOGY_API=${MUZOLOGY_API}`);
    console.info(`WEBSITE_URL=${WEBSITE_URL}`);
    // vercel details
    console.info(`VERCEL=${process.env.NEXT_PUBLIC_VERCEL}`);
    console.info(`VERCEL_ENV=${process.env.NEXT_PUBLIC_VERCEL_ENV}`);
    console.info(`VERCEL_URL=${process.env.NEXT_PUBLIC_VERCEL_URL}`);
    console.info(`VERCEL_GIT_REPO_SLUG=${process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG}`);
    console.info(`VERCEL_GIT_REPO_OWNER=${process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER}`);
    console.info(`VERCEL_GIT_REPO_ID=${process.env.NEXT_PUBLIC_VERCEL_GIT_REPO_ID}`);
    console.info(`VERCEL_GIT_COMMIT_SHA=${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}`);
    console.info(`VERCEL_GIT_COMMIT_MESSAGE=${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE}`);
    console.info(`VERCEL_GIT_COMMIT_AUTHOR_LOGIN=${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN}`);
    console.info(`VERCEL_GIT_COMMIT_AUTHOR_NAME=${process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME}`);
}
